// Font families
$icons-font-family: "Pyrat_icons_font";
$base-font-family:
        -apple-system,
        BlinkMacSystemFont,
        "Segoe UI",
        Roboto,
        Oxygen-Sans,
        "DejaVu Sans",
        Cantarell,
        "Helvetica Neue",
        sans-serif;

// Font attributes
$body-font-size: 13px; // 13px is the font-size used mainly in the whole PyRAT.
$base-font-style: normal;
$base-font-weight: normal;

// Font sizes
$small-font-size: 11px;
$base-font-size: 12px;
$medium-font-size: $body-font-size; // 13px;
$medium-bigger-font-size: 14px;
$large-font-size: 15px;
$larger-font-size: 16px;
$extra-small-font-size: 10px;
$extra-large-font-size: 18px;


// Colors
//
// Style guide: colors


// Font colors
//
// Colors we use for text.
//
// Colors:
// $primary-color:  #000  - Base text color.
// $contrast-color: #fff  - Contrast text color.
//
// Weight: 1
//
// Style guide: colors.fonts
$primary-color: #000; // Simply Black
$contrast-color: #fff; // White


// Grey tones
//
// The gray pallet is based on a middle gray color and from there all the other
// grays we use in PyRAT are created (Numbers based on how far or close the
// gray is to `#000` or `#fff`. Like from `$shade-color-97` to `#fff` only 2 colors are available.
// It doesn't mean we can have the 100 different color. But it gives us accuracy about
// the particular color we have or we may need.
//
// Colors:
// $shade-color-21: #363636  - Base middle gray color, darkened by 29 percent.
// $shade-color-30: #4d4d4d  - Base middle gray color, darkened by 20 percent.
// $shade-color-50: #808080  - Base middle gray color (rgb 128 128 128).
// $shade-color-59: #979797  - Base middle gray color, lightened by 9 percent.
// $shade-color-74: #bdbdbd  - Base middle gray color, lightened by 24 percent.
// $shade-color-82: #d2d2d2  - Base middle gray color, lightened by 32 percent.
// $shade-color-90: #e6e6e6  - Base middle gray color, lightened by 40 percent.
// $shade-color-93: #eeeeee  - Base middle gray color, lightened by 43 percent.
// $shade-color-97: #f8f8f8  - Base middle gray color, lightened by 47 percent.
//
// Weight: 2
//
// Style guide: colors.grays
$shade-color-50: #808080;
$shade-color-21: darken($shade-color-50, 29);
$shade-color-30: darken($shade-color-50, 20);
$shade-color-59: lighten($shade-color-50, 9);
$shade-color-74: lighten($shade-color-50, 24);
$shade-color-82: lighten($shade-color-50, 32);
$shade-color-90: lighten($shade-color-50, 40);
$shade-color-93: lighten($shade-color-50, 43);
$shade-color-97: lighten($shade-color-50, 47);

// Highlighting colors
//
// Colors we use for highlighting states or actions.
//
// Colors:
// $success-color:  #00b300   - Green sucess color. (Used also for high level priority).
// $invalid-color:  #CD5C5C   - Muted red invalid input data color.
// $error-color:    #b30000   - Red error color.  (Used also for low level priority).
// $light-red:      #ff6063   - Used for some crosses or for any need of a red color not so dark.
// $warning-color:  #e65100   - Used hover in delete icon available presets list filter. Also warning invoice report message.
// $yellow-color:   #e7b11a   - Used in Star for mark element as favorite in list filters.
// $purple-color:   #9370db   - Used in the main page to select the color depending the selection of the user.
// $violet-color:   #642e91   - Same as `$purple-color` … also used in userlist.
//
// Weight: 3
//
// Style guide: colors.highlighting
$success-color: #00b300;
$invalid-color: #CD5C5C;
$error-color: #b30000;
$light-red: #ff6063;
$warning-color: #e65100;
$yellow-color: #e7b11a;
$purple-color: #9370db;
$violet-color: #642e91;

// Priority colors
//
// Colors used for the priority in requests, orders, etc.
//
// Colors:
// $emergency-level-color:  #b30000  - Color for emergency priority.
// $high-level-color:       #b30000  - Color for high priority.
// $medium-level-color:     #0000dd  - Color for medium priority.
// $low-level-color:        #00b300  - Color for low priority.
//
// Weight: 4
//
// Style guide: colors.priories
$emergency-level-color: $error-color;
$high-level-color: $error-color;
$medium-level-color: #0000dd;
$low-level-color: $success-color;

// Primary colors
//
// Green tones used in PyRAT.
//
// Colors:
// $basic_color_50:  #f2fdf8  - …
// $basic_color_100: #cff2e2  - …
// $basic_color_200: #99dabc  - …
// $basic_color_300: #83d1ad  - …
// $basic_color_400: #69c89b  - …
// $basic_color_500: #54b88b  - …
// $basic_color_600: #3ea775  - …
// $basic_color_700: #338960  - …
// $basic_color_800: #186943  - …
// $basic_color_900: #1f4a36  - …
//
// Weight: 5
//
// Style guide: colors.greens
$basic_color_50: #f2fdf8;
$basic_color_100: #cff2e2;
$basic_color_200: #99dabc;
$basic_color_300: #83d1ad;
$basic_color_400: #69c89b;
$basic_color_500: #54b88b;
$basic_color_600: #3ea775;
$basic_color_700: #338960;
$basic_color_800: #186943;
$basic_color_900: #1f4a36;

// Secondary colors
//
// Blue tones used in PyRAT.
//
// Colors:
// $second_color_100: #d8e5ea  - …
// $second_color_300: #afd0dd  - …
// $second_color_500: #6297a8  - …
// $second_color_600: #3b7182  - …
// $second_color_700: #007599  - …
// $second_color_900: #08425f  - …
//
// Weight: 6
//
// Style guide: colors.blues
$second_color_100: #d8e5ea;
$second_color_300: #afd0dd;
$second_color_500: #6297a8;
$second_color_600: #3b7182;
$second_color_700: #007599;
$second_color_900: #08425f;


// -- MIXIN ELEMENTS ------------------------------------------------------------------------------------------------ //
@mixin linear-gradient-shadow($top, $second, $third, $bottom){
    background: $top; /* Old browsers */
    background: linear-gradient(to bottom, $top 15%, $second 16%, $third 84%, $bottom 85%); /* W3C */
}

// https://stackoverflow.com/questions/6071987/line-height-as-a-percentage-not-working#33221185
@mixin vertical-align-assistant($align: middle) {

    &::before {
        content: '';
        display: inline-block;
        height: 100%;
        vertical-align: $align;
        // you can add font-size 0 here and restore in the children to prevent
        // the inline-block white-space to mess the width of your elements
        font-size: 0;
    }

    > * {
        vertical-align: $align;
        // although you need to know the font-size, because "inherit" is 0
        font-size: $base-font-size;
    }
}

    @mixin invalid-data {
        border-bottom-color: $invalid-color;
        box-shadow: inset 0 -1px 0 $invalid-color;
}
