@import "pyrat_basic";

.lang_icon {
    cursor: pointer;
    font-size: 16px;
    text-align: center;
    color: $contrast-color;
    font-weight: bold
}

.lang_box {
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
    background-color: $shade-color-97;
    color: $shade-color-30;
    font-size: $base-font-size;
    padding: 8px;

    div {
        color: $shade-color-59;
        cursor: pointer;
        font-weight: bold;
        line-height: 28px;

        &:hover {
            color: $shade-color-30;
        }
    }
}
